import React from 'react';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import Hero from '../components/homepage/Hero';
import Services from '../components/homepage/Services';
import DownloadApp from '../components/homepage/DownloadApp';
import Testimonials from '../components/homepage/Testimonials';
import Staff from '../components/homepage/Staff';
import Blog from '../components/homepage/Blog';
import Events from '../components/homepage/Events';
import Map from '../components/homepage/Map';
import '../styles/app.scss';
import { useTestimonials } from '../hooks/getHomeTestimonials';
import { useLatestBlog } from '../hooks/getLatestBlog';
import { useHomeSeo } from '../hooks/getHomeSEO';
import SEO from '../components/seo';

const IndexPage = () => {
    const dataTestimonials = useTestimonials();
    const testimonialsList = dataTestimonials.wordpressPage.acf;

    const dataSEO = useHomeSeo();
    const homeSEO = dataSEO.wordpressPage;

    const dataBlog = useLatestBlog();
    const blogList = dataBlog.allWordpressPost.nodes;
    
    let test_title = homeSEO.yoast_title;
    let new_seo_title = test_title.replace("&#039;", "'");
    
    return (
        <>
            <SEO
                title={new_seo_title}
                meta={homeSEO.yoast_meta}
                description={homeSEO.excerpt}
            />
            <HeaderMain />					
            <Hero />
            <Services />
            <DownloadApp />
            <Testimonials testimonialsList={testimonialsList} />
            <Blog blogList={blogList} />
            <Events />
            <Map />								
            <Footer />
        </>
    );
};

export default IndexPage;
